import React from 'react'
import {IProps} from "./feature"
import useHover from "../../util/use-hover"

export default function FeatureSecure({...props}: IProps) {

    const [ref, isHovered] = useHover()

    return (
        <svg className={props.className} ref={ref} width="262" height="133" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="260" height="130.804" rx="6.218"
                   stroke="#E4E4E4" strokeWidth="2"/>
              <g className={!isHovered ? "" : "feature"} style={{transition: 'all .5s ease-out'}}>
                  <path fill="#E4E4E4"
                        d="M119.859 44.338h4v10.857h-4zM138.145 44.338h4v10.857h-4zM119.859 44.338a11.142 11.142 0 1122.286 0h-4a7.144 7.144 0 00-7.143-7.142 7.141 7.141 0 00-7.142 7.142h-4.001z"/>
            <rect x="113" y="54.91" width="36" height="34.286" rx="2.286"
                  fill="#E4E4E4"/>
            <circle cx="131.142" cy="69.053" r="3.857" fill="#E4E4E4"/>
            <path d="M129.857 69.481h2.572l.857 9.429H129l.857-9.429z"
                  fill="#E4E4E4"/>
              </g>
        </svg>
    )
}