import React from 'react'
import {IProps} from "./feature"
import useHover from "../../util/use-hover"

export default function FeatureDirect({...props}: IProps) {

    const [ref, isHovered] = useHover()

    return (
        <svg className={props.className} ref={ref} width="262" height="133" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g className={!isHovered ? "" : "feature"} style={{transition: 'all .5s ease-out'}}>
            <circle cx="54.801" cy="66.349" r="13.66" fill="#E4E4E4"/>
            <circle cx="207.684" cy="66.349" r="13.66" fill="#E4E4E4"/>
            <path d="M54.8 66.35h152.881" stroke="#E4E4E4"
                  strokeWidth="5.232"/>
            </g>
            <rect x="1" y="1" width="260" height="130.804" rx="6.218"
                  stroke="#E4E4E4" strokeWidth="2"/>
        </svg>
    )
}