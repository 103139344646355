import React from 'react'
import {IProps} from "./feature";
import useHover from "../../util/use-hover"

export default function FeatureFast({...props}: IProps) {

    const [ref, isHovered] = useHover()

    return (
        <svg className={props.className} ref={ref} width="262" height="133" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g className={!isHovered ? "" : "feature"} style={{transition: 'all .5s ease-out'}}>
            <path
                d="M135.728 94.35l-3.372-22.03-9.92-.729a1 1 0 01-.926-.987l-.232-23.074a1 1 0 01.959-1.009l13.794-.57a1 1 0 011.03 1.147l-2.687 17.993 8.398 1.067a1 1 0 01.834 1.273l-7.878 26.918z"
                fill="#E4E4E4"/>
            </g>
            <rect x="1" y="1" width="260" height="130.804" rx="6.218"
                  stroke="#E4E4E4" strokeWidth="2"/>
        </svg>
    )
}